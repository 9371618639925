@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100vh;
}

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2a4575;
  color: #eee;
  max-width: 85vw;
  margin: 1em auto;
}

h1 {
  font-size: 7vw;
  font-weight: 100;
  margin: 0;
  line-height: 1.5ch;
  padding-bottom: 1rem;
}

h1,
h2 {
  margin: 0;
  padding-left: 1.25rem;
}

.container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  padding: 2em;
  width: 85%;
  margin: auto;
}

.image {
  position: relative;
  max-width: 90%;
  min-width: 300px;
}

.image img {
  box-shadow: 0 20px 30px -10px rgb(40, 60, 80);
  border-radius: 10px;
  max-width: 100%;
  object-fit: contain;
  filter: brightness(0.5);
}

.greeting {
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: #fff;
}

.greeting h2 {
  font-family: monospace;
  font-size: 2vw;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation: keystroke 2s steps(20), blink 0.5s step-end infinite alternate;
  border-right: 3px solid white;
}

@keyframes keystroke {
  from {
    width: 0;
  }
  to {
    width: 50%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 90%;
}

.contact {
  margin: 1.25em auto;
  text-align: center;
}

.contact h2 {
  font-weight: 100;
}

.social {
  margin: auto;
  display: flex;
  justify-content: space-around;
}

.social-link {
  margin: 1em;
  transition: transform 750ms;
}

.contact svg {
  fill: #ffc528;
  width: 50px;
}

.social-link:hover {
  transform: scale(2);
}

.social-link:active {
  border: dashed 1px #e05848;
}

@media (min-width: 725px) {
  nav {
    justify-content: space-between;
  }
  .contact h2 {
    font-size: 5vw;
  }
}
