.projectPage {
  display: flex;
  height: 80vh;
}

.projects {
  margin: auto;
  padding: 2em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 50px;
  row-gap: 50px;
}

.project {
  padding: 2em;
  /* background-color: #1d1a17; */
  background-color: #5980c2;
  color: #e8e8e8;
  margin: auto;
  border-radius: 10px;
}

.pageTitle {
  align-self: center;
  text-align: center;
}

h2 {
  font-size: 2em;
}

svg {
  fill: #ffc528;
  width: 30px;
}

header h3 {
  color: #e8e8e8;
  margin-top: 1em;
  font-size: 1.5em;
}

.projectImage {
  padding: 1em 0;
  max-width: 100%;
}

.projectLinks {
  display: flex;
  justify-content: space-between;
}

.description {
  margin: 1em 0;
}

.project footer {
  margin-top: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

footer > div {
  margin: 0 1em 1em 0;
  font-size: 0.75em;
  opacity: 0.8;
}
