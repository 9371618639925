@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100vh;
}

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2a4575;
  color: #eee;
  max-width: 85vw;
  margin: 1em auto;
}

h1 {
  font-size: 7vw;
  font-weight: 100;
  margin: 0;
  line-height: 1.5ch;
  padding-bottom: 1rem;
}

h1,
h2 {
  margin: 0;
  padding-left: 1.25rem;
}

.container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  padding: 2em;
  width: 85%;
  margin: auto;
}

.image {
  position: relative;
  max-width: 90%;
  min-width: 300px;
}

.image img {
  box-shadow: 0 20px 30px -10px rgb(40, 60, 80);
  border-radius: 10px;
  max-width: 100%;
  object-fit: contain;
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

.greeting {
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: #fff;
}

.greeting h2 {
  font-family: monospace;
  font-size: 2vw;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  -webkit-animation: keystroke 2s steps(20), blink 0.5s step-end infinite alternate;
          animation: keystroke 2s steps(20), blink 0.5s step-end infinite alternate;
  border-right: 3px solid white;
}

@-webkit-keyframes keystroke {
  from {
    width: 0;
  }
  to {
    width: 50%;
  }
}

@keyframes keystroke {
  from {
    width: 0;
  }
  to {
    width: 50%;
  }
}

@-webkit-keyframes blink {
  50% {
    border-color: transparent;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 90%;
}

.contact {
  margin: 1.25em auto;
  text-align: center;
}

.contact h2 {
  font-weight: 100;
}

.social {
  margin: auto;
  display: flex;
  justify-content: space-around;
}

.social-link {
  margin: 1em;
  transition: -webkit-transform 750ms;
  transition: transform 750ms;
  transition: transform 750ms, -webkit-transform 750ms;
}

.contact svg {
  fill: #ffc528;
  width: 50px;
}

.social-link:hover {
  -webkit-transform: scale(2);
          transform: scale(2);
}

.social-link:active {
  border: dashed 1px #e05848;
}

@media (min-width: 725px) {
  nav {
    justify-content: space-between;
  }
  .contact h2 {
    font-size: 5vw;
  }
}

nav {
  padding: 1em 2em;
  display: flex;
  justify-content: space-between;
}

nav p {
  display: flex;
  align-items: center;
}

.logo {
  font-weight: bold;
  margin: 0;
  text-decoration: none;
  color: #eee;
  font-size: 1.25em;
}

nav > a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu {
  margin-right: 2em;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menu-item {
  text-decoration: none;
  font-size: 0.9em;
  font-weight: bold;
  margin-left: 2em;
  text-decoration: none;
  color: #d5d5d5;
}

.cta {
  /* background-color: #4a45ba; */
  background-color: #e05848;
  color: #eee;
  padding: 1.2em;
  border-radius: 10px;
}

.active {
  -webkit-text-decoration: underline solid 3px;
          text-decoration: underline solid 3px;
  text-underline-offset: 5px;
  color: #eee;
  color: #ffc528;
}

.codeblock {
  background: #1d1a17;
  border-radius: 15px;
  padding: 1.2em;
  box-shadow: 0 20px 30px -10px rgb(20, 20, 80);
}

.objectContents {
  padding: 0.75em 0;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-row-gap: 0.75em;
  row-gap: 0.75em;
  grid-column-gap: 0.75em;
  -webkit-column-gap: 0.75em;
          column-gap: 0.75em;
}

.key {
  text-align: right;
}

.codeline {
  padding-left: 2em;
  padding-top: 0.5em;
}

.properties {
  color: #2ce2db;
}

.values {
  /* color: #ff8a37; */
  color: #eee;
}

.delimiter {
  color: #f0eff1;
}

.variables {
  color: #f17ebb;
}

.declarations {
  color: #fede5d;
}

.skills {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.skills img {
  padding: 0 1em 0.5em 0;
}

.projectPage {
  display: flex;
  height: 80vh;
}

.projects {
  margin: auto;
  padding: 2em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 50px;
  -webkit-column-gap: 50px;
          column-gap: 50px;
  grid-row-gap: 50px;
  row-gap: 50px;
}

.project {
  padding: 2em;
  /* background-color: #1d1a17; */
  background-color: #5980c2;
  color: #e8e8e8;
  margin: auto;
  border-radius: 10px;
}

.pageTitle {
  align-self: center;
  text-align: center;
}

h2 {
  font-size: 2em;
}

svg {
  fill: #ffc528;
  width: 30px;
}

header h3 {
  color: #e8e8e8;
  margin-top: 1em;
  font-size: 1.5em;
}

.projectImage {
  padding: 1em 0;
  max-width: 100%;
}

.projectLinks {
  display: flex;
  justify-content: space-between;
}

.description {
  margin: 1em 0;
}

.project footer {
  margin-top: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

footer > div {
  margin: 0 1em 1em 0;
  font-size: 0.75em;
  opacity: 0.8;
}

