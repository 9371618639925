.codeblock {
  background: #1d1a17;
  border-radius: 15px;
  padding: 1.2em;
  box-shadow: 0 20px 30px -10px rgb(20, 20, 80);
}

.objectContents {
  padding: 0.75em 0;
  display: grid;
  grid-template-columns: 1fr 3fr;
  row-gap: 0.75em;
  column-gap: 0.75em;
}

.key {
  text-align: right;
}

.codeline {
  padding-left: 2em;
  padding-top: 0.5em;
}

.properties {
  color: #2ce2db;
}

.values {
  /* color: #ff8a37; */
  color: #eee;
}

.delimiter {
  color: #f0eff1;
}

.variables {
  color: #f17ebb;
}

.declarations {
  color: #fede5d;
}

.skills {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.skills img {
  padding: 0 1em 0.5em 0;
}
